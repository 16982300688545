import { Permission, UserPrerequisite } from '../entities/user.entity';

export { UserPrerequisite };

export class CurrentUserVm {
  private permissions: Set<Permission>;

  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly displayName: string,
    public readonly lang: string,
    public readonly isEmailVerified: boolean,
    public readonly currentTermsAreAccepted: boolean,
    permissions: Permission[],
    public readonly firstname?: string,
    public readonly lastname?: string,
    public readonly unmetPrerequisites: UserPrerequisite[] = [],
  ) {
    this.permissions = new Set<Permission>(permissions);
  }

  checkPermission(...permissions: Permission[]): boolean {
    return permissions.some((permission) => this.permissions.has(permission));
  }
}
